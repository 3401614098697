<template>
  <div class="exhibition-decoration"
       :style="{'height': height, 'width': width, 'background-color': backgroundColor, 'top':top, 'left':left, 'right':right, 'bottom':bottom}">
  </div>
</template>

<script>
  export default {
    name: "ExhibitionDecoration",
    props:{
      top:{
        type: String,
        default:'auto'
      },
      left:{
        type: String,
        default:'auto'
      },
      right:{
        type: String,
        default:'auto'
      },
      bottom:{
        type: String,
        default:'auto'
      },
      height:{
        type: String,
        default: "20px"
      },
      width:{
        type: String,
        default: "20px"
      },
      backgroundColor:{
        type: String,
        default: 'rgba(95, 134, 231, 0.29)'
      }
    }
  }
</script>

<style scoped>
  .exhibition-decoration{
    position: absolute;
  }
</style>
