<template>
    <router-link class="business-panel" :class="{'expanded': isExpanded}" :index="index" :to="href">
<!--      <router-link :to="href">-->
        <div class="content w-l-column-rs-compact">
          <div class="icon">
            <slot name="panelIcon"></slot>
          </div>
          <div class="title font-14 fc-d4">
            <strong><slot name="panelTitle"></slot></strong>
          </div>
          <div class="subject font-12 fc-d7 ellipsis ellipsis-3">
            <slot name="panelSubject"></slot>
          </div>
        </div>
<!--      </router-link>-->
    </router-link>
</template>

<script>
  export default {
    name: "BusinessPanel",
    props:{
      isExpanded:{
        type: Boolean,
        default: false
      },
      index:{
        type: String,
        require: true
      },
      href: String
      // iconSrc:{
      //   type: String,
      //   require: true
      // },
      // title:{
      //   type:String,
      //   require: true
      // },
      // abstract:{
      //   type: String,
      //   require: true
      // }
    }
  }
</script>

<style scoped>
  .business-panel[index='0']{
    /*transform: translateY(20px);*/
    margin-bottom: 90px;
  }
  .business-panel[index='1']{
    /*transform: translateY(20px);*/
    margin-top: 90px;
  }
  .business-panel{
    display: block;
    margin: 11px;
    width: 278px;
    height: 246px;
    box-shadow: 0 5px 13px lightgray;
    transition: 0.3s all;
    /*float: left;*/
  }
  .business-panel:hover{
    cursor: pointer;
    box-shadow: 0 3px 10px lightgray;
    transform: translateY(-3px);
  }
  .business-panel.expanded{
    width: 294px;
    height: 1164px;
  }
  .business-panel .content{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px 35px;
  }
  .business-panel .content>div{
    margin: 10px 0;
  }

  .business-panel .content .subject{
    line-height: 1.75;
    width: 100%;
    height: 60px;
  }
</style>
