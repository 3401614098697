<template>
  <wit-block-content-container class="home-stories"
                               mask-color="rgba(0,0,0,0.8)"
                               bg-img-url="/images/stories/story_bg.jpg">
    <div class="title-block w-l-column-center-compact">
      <div class="panel-title fc-white font-bold">
        STORIES
      </div>
      <div class="tip fc-d9 font-18">
        /客/户/评/价/
      </div>
    </div>
    <div class="container left-container">
      <story-viewer class="viewer" :details="storyDetails"></story-viewer>
    </div>
    <div class="container right-container w-l-column-rs-compact">
      <div class="custom-info w-l-row-cs-compact">
        <el-avatar :size="80" :src="icon" class="avatar" fit="cover"></el-avatar>
        <div class="info w-l-column-rs-compact">
          <div class="name font-30 fc-white">
            {{uName}}
          </div>
          <div class="position font-12 fc-d8">
            {{position}}
          </div>
        </div>
      </div>
      <div class="decoration font-bold font-100 fc-d5">“</div>
      <div class="comment font-14 fc-very-lightgray">
        <p>{{uComment}}</p>
      </div>
    </div>
  </wit-block-content-container>
</template>

<script>
  import WitBlockContentContainer
    from "@/components/common/WitBaseUI/WitBlockContentContainer/WitBlockContentContainer";
  import StoryViewer from "@/views/home/comps/HomeStories/StoryViewer";
  import {getHomeStoryData} from "@/network/home";
  export default {
    name: "HomeStories",
    components:{
      WitBlockContentContainer,
      StoryViewer
    },
    data(){
      return {
        storyOverview: null
      }
    },
    created() {
      getHomeStoryData().then(response => {
        if(response && response.length > 0){
          this.storyOverview = response[0]
        }
      })
    },
    computed: {
      storyDetails(){
        if (this.storyOverview && this.storyOverview.storyDetails) {
          return this.storyOverview.storyDetails
        } else {
          return []
        }
      },
      uName(){
        return (this.storyOverview && this.storyOverview.storyDetails) ? this.storyOverview.uName : ''
      },
      position(){
        return (this.storyOverview && this.storyOverview.position) ? this.storyOverview.position : ''
      },
      uComment(){
        return (this.storyOverview && this.storyOverview.uComment) ? this.storyOverview.uComment : ''
      },
      icon(){
        return (this.storyOverview && this.storyOverview.icon) ? this.storyOverview.icon : ''
      }
    }
  }
</script>

<style scoped>
  .home-stories{
    position: relative;
    margin: 50px 0;
    width: 100%;
    height: 754px;
  }
  .home-stories .title-block{
    width: 100%;
    padding: 130px 0 70px;
  }
  .home-stories .title-block .panel-title{
    letter-spacing: 10px;
  }
  .home-stories  .title-block .tip{
    letter-spacing: 3px;
  }
  .home-stories .container{
    width: 50%;
    height: calc(100% - 261px);
  }
  .home-stories .left-container{
    float: left;
    position: relative;
  }
  .home-stories .left-container .viewer{
    position: absolute;
    top: 50px
  }
  .home-stories .right-container{
    float: right;
    padding: 0 60px;
  }
  .home-stories .right-container>div{
    margin: 10px 0;
  }
  .home-stories .right-container .custom-info .info{
    margin-left: 20px;
  }
  .home-stories .right-container .custom-info .info>div{
    margin: 5px 0;
  }
  .home-stories .right-container .custom-info .avatar{
    border: 5px solid white;
  }
  .home-stories .right-container .decoration{
    height: 50px;
  }
  .home-stories .right-container .comment{
    line-height: 2;
  }
</style>
