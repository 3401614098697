<template>
  <div class="home">
    <el-main class="wit-home-container">
      <home-carousel></home-carousel>
      <home-business></home-business>
<!--      <home-focus></home-focus>-->
<!--      <home-production></home-production>-->
<!--      <home-solution @ContentUpdated="ContentUpdated"></home-solution>-->
<!--      <home-stories></home-stories>-->
      <home-partner @ContentUpdated="ContentUpdated"></home-partner>
<!--      <home-information></home-information>-->
    </el-main>
  </div>
</template>

<script>
  import HomeCarousel from "@/views/home/comps/HomeCarousel/HomeCarousel";
  import HomeBusiness from "@/views/home/comps/HomeBusiness/HomeBusiness";
  import HomeFocus from "@/views/home/comps/HomeFocus/HomeFocus";
  import HomeProduction from "@/views/home/comps/HomeProduction/HomeProduction";
  import HomeSolution from "@/views/home/comps/HomeSolution/HomeSolution";
  import HomeStories from "@/views/home/comps/HomeStories/HomeStories";
  import HomePartner from "@/views/home/comps/HomePartner/HomePartner";
  import HomeInformation from "@/views/home/comps/HomeInformation/HomeInformation";
  export default {
    name: "Home",
    components:{
      HomeCarousel,
      HomeBusiness,
      HomeFocus,
      HomeProduction,
      HomeSolution,
      HomeStories,
      HomePartner,
      HomeInformation
    },
    methods:{
      ContentUpdated(){
        this.$emit('ContentUpdated')
      }
    },
    mounted() {
      this.$emit('ContentMounted')
    },
    activated() {
      this.$emit('ContentActivated')
    }
  }
</script>

<style scoped>
  .wit-home-container{
    padding: 0 !important;
  }
</style>
