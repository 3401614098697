import {request} from "@/network/request";

export function getCarouselData(){
  return request({
    url: "/carousel"
  })
}
export function getHomeBusinessData(){
  return request({
    url: "/business"
  })
}

export function getHomePartnersData(){
  return request({
    url: "/partners"
  })
}

export function getHomeProductionsData(){
  return request({
    url: "/productions"
  })
}

export function getHomeSolutionsData(){
  return request({
    url: "/solutions"
  })
}

export function getHomeStoryData(){
  return request({
    url: "/story"
  })
}
