<template>
  <div class="home-focus">
    <div class="mask">
      <div class="home-focus-wrapper item-center">
        <div class="detail-link">
          <detail-link-panel text-align="left">
            <template v-slot:title-tip>NEW GENERATION</template>
            <template v-slot:title-content>新一代 AI 生产力平台</template>
            <template v-slot:description>
              <p>自主研发的新一代AI生产力平台，致力于深度学习，简单开发，帮助企业和开发者提升AI生产效率、规范生产流程。</p>
              <p>目前，已实现互联网全员使用，并通过逐步开源核心框架、开放算力和数据平台的形式，将AI生产力共享给企业、高校和研究机构中的广大开发者、师生和学者。</p>
            </template>
          </detail-link-panel>
        </div>
        <focus-exhibition class="exhibition"></focus-exhibition>
      </div>
    </div>
  </div>
</template>

<script>
  import DetailLinkPanel from "@/components/content/DetailLinkPanel/DetailLinkPanel";
  import FocusExhibition from "@/views/home/comps/HomeFocus/FocusExhibition";
  export default {
    name: "HomeFocus",
    components:{
      DetailLinkPanel,
      FocusExhibition
    }
  }
</script>

<style scoped>
  .home-focus{
    width: 100%;
    height: 829px;
    background-image: url("/images/focus/exhibition.jpg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .home-focus .mask{
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.95);
  }
  .home-focus .home-focus-wrapper{
    max-width: var(--content-wrapper-width);
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  .home-focus .home-focus-wrapper .detail-link{
    width: 50%;
    height: 100%;
  }
  .home-focus .home-focus-wrapper .exhibition{
    width: 50%;
    height: 100%;
  }
</style>
