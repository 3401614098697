<template>
  <div class="news-item item-column-center">
    <div class="time">
      <p>2020-11-25</p>
    </div>
    <div class="title">
      <p>人工智能正在推动芯片的复兴</p>
    </div>
    <div class="abstract">
      <p>人工智能（AI）引发了半导体创新的“新黄金时代”——机器学习带来独特的市场需求，第一次激发了企业家们，去重新思考芯片架构的基本原则。</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: "NewsItem"
  }
</script>

<style scoped>
  .news-item{
    width: 390px;
    height: 280px;
    background-color: white;
    box-shadow: 0 0 10px lightgray;
    cursor: pointer;
    transition: 0.5s all;
    margin: 5px;
    align-items: flex-start;
    padding: 0 35px;
  }
  .news-item>div{
    margin: 10px 0;
  }
  .news-item:hover{
    transform: translate3d(0, -8px, 8px);
    background-color: rgb(95, 134, 231);
    box-shadow: 0 5px 30px 0 rgba(0,0,0,0.1);
  }
  .news-item .time{
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 24px;
    color: #cccccc;
  }
  .news-item .title{
    color: black;
    font-weight: bold;
    line-height: 22px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    font-size: 16px;
  }
  .news-item .abstract{
    font-size: 12px;
    color: #666666;
    line-height: 22px;
  }
  .news-item .abstract p{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
  }
  .news-item:hover .time,
  .news-item:hover .title,
  .news-item:hover .abstract{
    color: white;
  }
</style>
