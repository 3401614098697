<template>
  <wit-block-content-container class="home-partner" v-if="isShow">
    <div class="title-block w-l-column-center-compact">
      <div class="panel-title fc-d3 font-bold">
        PARTNER
      </div>
      <div class="tip fc-d7 font-18">
        /合/作/伙/伴/
      </div>
    </div>
    <div class="partner-container w-l-rw-center-compact">
      <partner-item v-for="(item, index) in partners"
                    :img-src="item.icon"
                    :link="item.link"
                    :key="index"
                    :index="index"></partner-item>
    </div>
  </wit-block-content-container>
</template>

<script>
  import WitBlockContentContainer
    from "@/components/common/WitBaseUI/WitBlockContentContainer/WitBlockContentContainer";
  import PartnerItem from "@/views/home/comps/HomePartner/PartnerItem";
  import {getHomePartnersData} from "@/network/home";
  export default {
    name: "HomePartner",
    components:{
      WitBlockContentContainer,
      PartnerItem
    },
    data(){
      return{
        partners:[]
      }
    },
    computed:{
      isShow(){
        return !(this.partners.length === 0)
      }
    },
    created() {
      getHomePartnersData().then(response => {
        if(response){
          this.partners = response
        }
      })
    },
    updated() {
      this.$emit('ContentUpdated')
    }
  }
</script>

<style scoped>
  .home-partner .title-block{
    width: 100%;
    padding: 100px 0 0;
  }
  .home-partner .title-block .panel-title{
    letter-spacing: 10px;
  }
  .home-partner .title-block .tip{
    letter-spacing: 3px;
  }
</style>
