<template>
  <wit-block-content-container class="home-production">
    <div class="title-block w-l-column-center-compact">
      <div class="panel-title fc-d3 font-bold">
        PRODUCTION
      </div>
      <div class="tip fc-d7 font-18">
        /产/品/长/廊/
      </div>
    </div>
    <production-gallery class="gallery" :img-src-list="imgList"></production-gallery>
  </wit-block-content-container>
</template>

<script>
  import WitBlockContentContainer
    from "@/components/common/WitBaseUI/WitBlockContentContainer/WitBlockContentContainer";
  import ProductionGallery from "@/views/home/comps/HomeProduction/ProductionGallery";
  import {getHomeProductionsData} from "@/network/home";
  export default {
    name: "HomeProduction",
    components:{
      WitBlockContentContainer,
      ProductionGallery
    },
    data(){
      return{
        imgList:[]
      }
    },
    created() {
      getHomeProductionsData().then(response => {
        this.imgList = this.imgListTrim(response);
      })
    },
    methods:{
      imgListTrim(data){
        return  data.map(item => {return item.imgUrl})
      }
    }
  }
</script>

<style scoped>
  .home-production{
    width: 100%;
    /*height: 825px;*/
    margin-bottom: 80px;
  }
  .home-production .title-block{
    width: 100%;
    margin: 100px 0;
  }
  .home-production .title-block .panel-title{
    letter-spacing: 10px;
  }
  .home-production .title-block .tip{
    letter-spacing: 3px;
  }
  .home-production .gallery{
    width: 100%;
    height: 608px;
  }
</style>
