<template>
  <wit-block-content-container class="home-solution">
    <div class="title-block w-l-column-center-compact">
      <div class="panel-title fc-d3 font-bold">
        SOLUTION
      </div>
      <div class="tip fc-d7 font-18">
        /智/能/方/案/
      </div>
    </div>
    <div class="solution-content w-l-row-center-compact">
      <solution-item v-for="(item, index) in solutionData" :key="index" :icon-url="item.link">
        <template v-slot:icon><img :src="item.imgUrl" alt=""></template>
        <template v-slot:title><p>{{ item.title }}</p></template>
        <template v-slot:abstract><p>{{ item.solutionAbstract }}</p></template>
      </solution-item>
    </div>
  </wit-block-content-container>
</template>

<script>
  import WitBlockContentContainer
    from "@/components/common/WitBaseUI/WitBlockContentContainer/WitBlockContentContainer";
  import SolutionItem from "@/views/home/comps/HomeSolution/SolutionItem";
  import {getHomeSolutionsData} from "@/network/home";
  export default {
    name: "HomeSolution",
    components:{
      WitBlockContentContainer,
      SolutionItem
    },
    data(){
      return{
        solutionData:[]
      }
    },
    created() {
      getHomeSolutionsData().then(response => {
        if(response){
          this.solutionData = response
        }
      })
    }
  }
</script>

<style scoped>
  .home-solution{
    width: 100%;
  }
  .home-solution .title-block{
    width: 100%;
    margin: 100px 0 0;
  }
  .home-solution .title-block .panel-title{
    letter-spacing: 10px;
  }
  .home-solution .title-block .tip{
    letter-spacing: 3px;
  }
  .home-solution .solution-content{
    width: 100%;
    flex-wrap: wrap;
  }
</style>
