<template>
  <div class="focus-exhibition">
    <exhibition-decoration top="300px" left="0"></exhibition-decoration>
    <exhibition-decoration bottom="150px" right="0" width="13px" height="13px"></exhibition-decoration>
    <exhibition-decoration top="108px"
                           left="109px"
                           width="457px"
                           height="624px"
                           background-color="rgb(95, 134, 231)"></exhibition-decoration>
    <div class="exhibition-img">
      <img src="/images/focus/exhibition.jpg" alt="">
    </div>
    <div class="title-block item-center">
      <div class="year">
        <span>2020</span>
      </div>
      <div class="divider"></div>
      <div class="subject">
        <span>新一代互联网</span>
      </div>
    </div>
  </div>
</template>

<script>
  import ExhibitionDecoration from "@/views/home/comps/HomeFocus/ExhibitionDecoration";
  export default {
    name: "FocusExhibition",
    components:{
      ExhibitionDecoration
    }
  }
</script>

<style scoped>
  .focus-exhibition{
    position: relative;
  }
  .focus-exhibition .decoration-block{
    width: 20px;
    height: 20px;
    background-color: rgba(95, 134, 231, 0.29);
  }
  .focus-exhibition .exhibition-img{
    position: absolute;
    top: 94px;
    left: 95px
  }
  .focus-exhibition .exhibition-img img{
    height: 624px;
    width: 457px;
    object-fit: cover;
  }
  .focus-exhibition .title-block{
    position: absolute;
    width: 134px;
    height: 134px;
    top: 150px;
    left: 27px;
    background-color: rgba(95, 134, 231, 0.8);
    color: white;
    flex-direction: column;
  }
  .focus-exhibition .title-block .year{
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .focus-exhibition .title-block .subject{
    font-size: 14px;
  }
  .focus-exhibition .title-block .divider{
    position: absolute;
    top: calc(50% + 10px);
    left: calc(50% - 43px);
    display: block;
    margin: -0.5px 0 0;
    padding: 0;
    width: 86px;
    height: 1px;
    font-size: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #eeeeee;
  }
</style>
