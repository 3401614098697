<template>
  <wit-block-content-container class="home-business"
                               content-class="w-l-row-center-compact" mask-color="rgba(255,255,255,0.88)">
    <div class="panel-container w-l-rw-center-compact">
      <business-panel v-for="(item, index) in businessData" :index="index.toString()" :key="index" :href="item.content">
        <template v-slot:panelIcon>
          <img :src="item.iconSrc" alt="icon" class="icon-img">
        </template>
        <template v-slot:panelTitle>{{ item.title }}</template>
        <template v-slot:panelSubject>{{ item.businessSubject }}</template>
      </business-panel>
    </div>
    <div class="details-link">
      <detail-link-panel>
<!--        <template v-slot:title-tip>SOLUTION</template>-->
        <template v-slot:title-content>专业的AI农业深耕者</template>
        <template v-slot:description><p>基于核心AI算法与软硬件结合能力</p><p>提供专业的农业科技服务</p><p>转变农业生产模式</p></template>
      </detail-link-panel>
    </div>
  </wit-block-content-container>
</template>

<script>
  import WitBlockContentContainer
    from "@/components/common/WitBaseUI/WitBlockContentContainer/WitBlockContentContainer";
  import BusinessPanel from "@/components/content/BusinessPanel/BusinessPanel";
  import DetailLinkPanel from "@/components/content/DetailLinkPanel/DetailLinkPanel";
  import {getHomeBusinessData} from "@/network/home";
  export default {
    name: "HomeBusiness",
    components:{
      WitBlockContentContainer,
      BusinessPanel,
      DetailLinkPanel
    },
    data(){
      return{
        businessData: []
      }
    },
    computed: {
      isPhoneSize(){
        return this.$store.getters.winWidth < 960;
      }
    },
    created() {
      getHomeBusinessData().then(response => {
        if(response){
          this.businessData = response
        }
      })
    }
  }
</script>

<style scoped>
  .home-business{
    width: 100%;
    /*min-height: 822px;*/
    height: 822px;
    background-image: url("/images/business/solb-02.jpg") !important;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .home-business .panel-container{
    width: 50%;
    height: 100%;
  }
  .home-business .details-link{
    position: relative;
    width: 50%;
    height: 100%;
  }
  .icon-img{
    width: 57px;
    height: 53px;
  }
</style>
