<template>
  <div class="story-viewer">
    <div class="viewer-header w-l-flex">
      <i class="iconfont icon-dot"></i>
      <i class="iconfont icon-dot"></i>
      <i class="iconfont icon-dot"></i>
    </div>
    <div class="viewer-content">
      <div class="carousel">
        <el-carousel :interval="10000" height="100%">
          <el-carousel-item  v-for="(item, index) in details" :key="index" class="item">
            <el-image style="width: 100%; height: 100%" :src="item.url" fit="cover"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "StoryViewer",
    props:{
      details: {
        type: Array
      }
    }
  }
</script>

<style scoped>
  .story-viewer{
    width: 100%;
    height: 100%;
    --corner-radius: 10px;
    border-top-left-radius: var(--corner-radius);
    border-top-right-radius: var(--corner-radius);
  }
  .story-viewer .viewer-header{
    width: 100%;
    height: 25px;
    background-color: silver;
    border-top-left-radius: var(--corner-radius);
    border-top-right-radius: var(--corner-radius);
    padding-left: 10px;
  }
  .story-viewer .viewer-header i{
    color: #848684;
    font-size: 20px;
  }
  .story-viewer .viewer-content{
    width: 100%;
    height: calc(100% - 25px);
  }
  .story-viewer .viewer-content .carousel{
    width: 100%;
    height: 100%;
  }
</style>
