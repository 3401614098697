<template>
  <div class="detail-link-container w-l-column-center-compact" :class="{'text-right': isTextAlignRight, 'text-left': !isTextAlignRight}">
    <div class="title w-l-column-center-compact">
      <div class="page-sub-title fc-very-lightgray" style="z-index: 1;">
        <slot name="title-tip"></slot>
      </div>
      <div class="block-title fc-black" style="z-index: 1;">
        <slot name="title-content"></slot>
      </div>
    </div>
    <div class="description font-14 fc-d7" style="z-index: 1;">
      <slot name="description"></slot>
    </div>
    <div class="detail" style="z-index: 1;">
      <router-link to="/aboutus">
        <button class="more-detail-btn font-12 fc-white">更多详情</button>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: "DetailLinkPanel",
    props:{
      textAlign:{
        type: String,
        default: 'right'
      }
    },
    computed:{
      isTextAlignRight(){
        return this.textAlign === 'right'
      }
    }
  }
</script>

<style scoped>
  .detail-link-container{
    width: 100%;
    height: 100%;
  }
  .detail-link-container.text-right{
    align-items: flex-end;
  }
  .detail-link-container.text-left{
    align-items: flex-start;
  }
  .detail-link-container>div{
    margin: 20px 0;
  }
  .detail-link-container.text-right .title{
    align-items: flex-end;
  }
  .detail-link-container.text-left .title{
    align-items: flex-start;
  }
  .detail-link-container .title>div{
    margin: 8px 0;
  }

  .detail-link-container .title .block-title{
    font-weight: normal;
  }
  .detail-link-container .description>p{
    line-height: 2;
    max-width: 484px;
    letter-spacing: 1px;
    margin: 10px 0;
  }
  .detail-link-container.text-right .description>p{
    text-align: right;
  }
  .detail-link-container.text-left .description>p{
    text-align: left;
  }
  .detail-link-container .detail .more-detail-btn{
    width: 160px;
    height: 50px;
    background-color: #5f86e7;
    border: 1px solid #5f86e7;
    cursor: pointer;
    line-height: 48px;
    outline: none;
  }
  .detail-link-container .detail .more-detail-btn:hover{
    background-color: rgb(71, 108, 201);
  }
</style>
