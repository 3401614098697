<template>
  <div class="production-gallery" :class="{'hovered': isGalleryHovered}"
       @mouseenter="isGalleryHovered=true" @mouseleave="isGalleryHovered=false">
    <el-scrollbar class="gallery-scroll" ref="galleryScroll">
      <div class="item" :class="{'hovered': index===isImgIndexHovered}" v-for="(item, index) in imgSrcList" :key="index"
           @mouseenter="isImgIndexHovered=index" @mouseleave="isImgIndexHovered=-1">
        <wit-image
          style="width: 100%; height: 100%"
          :src="item" :preview-src-list="[item]" :ref="'img_'+index">
        </wit-image>
      </div>
      <div class="blank-place"></div>
    </el-scrollbar>
    <transition name="left"
                appear
                enter-active-class="animate__animated animate__zoomIn"
                leave-active-class="animate__animated animate__zoomOut">
      <el-button v-if="isGalleryHovered" class="gs-button left-gs-button" circle icon="iconfont icon-left"
                 @mousedown.native="mouseDown('left')"
                 @mouseup.native="mouseUp"></el-button>
    </transition>
    <transition name="right"
                appear
                enter-active-class="animate__animated animate__zoomIn"
                leave-active-class="animate__animated animate__zoomOut">
      <el-button v-if="isGalleryHovered" class="gs-button right-gs-button" circle icon="iconfont icon-right"
                 @mousedown.native="mouseDown('right')"
                 @mouseup.native="mouseUp"></el-button>
    </transition>
  </div>
</template>

<script>
  import WitImage from "@/components/common/WitBaseUI/WitImage/WitImage";
  export default {
    name: "ProductionGallery",
    components:{
      WitImage
    },
    props:{
      imgSrcList:{
        type: Array,
        default: []
      }
    },
    data(){
      return{
        currentScrollPos: 0,
        isImgIndexHovered: -1,
        isGalleryHovered: false,
        timer: null,
        isMouseUp: true,
        step: 15,
      }
    },
    computed:{
      isPosStart(){
        let ret = this.currentScrollPos <= 0
        if (ret) this.currentScrollPos = 0
        return ret
      },
      isPosEnd(){
        let eleScroll = this.$refs.galleryScroll.wrap
        let maxLeft = eleScroll.scrollWidth - eleScroll.clientWidth
        let ret = this.currentScrollPos >= maxLeft
        if (ret) this.currentScrollPos = maxLeft
        return ret
      }
    },
    methods: {
      mouseDown(type){
        this.isMouseUp = false
        this.timer = setInterval(() => {
          if(!this.isPosEnd && type === 'right') {
            this.currentScrollPos+=this.step
          }else if(!this.isPosStart && type === 'left'){
            this.currentScrollPos-=this.step
          }
          if (this.isPosStart || this.isPosEnd || this.isMouseUp) {
            clearInterval(this.timer)
            this.timer = null
            return null
          }
        }, 40)
      },
      mouseUp(){
        this.isMouseUp = true
      }
    },
    watch:{
      currentScrollPos(){
        let eleScroll = this.$refs.galleryScroll.wrap
        eleScroll.scrollTo(this.currentScrollPos, 0)
      }
    },
  }
</script>

<style scoped>
  .production-gallery{
    position: relative;
  }
  .production-gallery .gallery-scroll{
    height: 100%;
    width: 100%;
  }
  .production-gallery .gallery-scroll .item{
    width: 392px;
    height: 296px;
    margin: 4px;
  }
  .production-gallery .gallery-scroll .item.hovered{
    border: 5px solid #5f86e7;
  }
  .production-gallery .gallery-scroll .blank-place{
    height: 100%;
    width: 17px;
    visibility: hidden;
  }
  .production-gallery .gs-button{
    position: absolute;
    display: block;
    top: calc(50% - 42px);
    width: 84px;
    height: 84px;
    background-color: rgba(163, 163, 163, 0.5);
    border: none;
    color: white;
    /*font-size: 20px;*/
  }
  /*.production-gallery .gs-button{*/
  /*  !*display: none;*!*/
  /*}*/
  .production-gallery .gs-button:hover{
    background-color: rgba(80, 80, 80, 0.9);
  }
  .production-gallery .gs-button.left-gs-button{
    left: 10px;
  }
  .production-gallery .gs-button.right-gs-button{
    right: 10px;
  }
</style>
