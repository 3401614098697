<template>
  <div class="solution-item item-column-center">
    <div class="icon">
      <a :href="this.iconUrl">
        <slot name="icon"></slot>
      </a>
    </div>
    <div class="title">
      <slot name="title"></slot>
    </div>
    <div class="abstract">
      <slot name="abstract"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SolutionItem",
    props:{
      iconUrl:{
        type: String,
        default: "javascript:void(0)"
      }
    }
  }
</script>

<style scoped>
  .solution-item{
    width: 286px;
    height: 350px;
  }
  .solution-item>div{
    margin: 10px 0;
  }
  .solution-item .icon{
    width: 55px;
    height: 55px;
    margin: 30px 0;
  }
  .solution-item .icon img{
    width: 100%;
    height: 100%;
  }
  .solution-item .title{
    font-size: 16px;
    font-weight: bold;
  }
  .solution-item .abstract{
    line-height: 2;
    color: #777777;
    text-align: justify;
    font-size: 12px;
    padding: 0 33px;
  }
</style>
