<template>
  <wit-block-content-container class="home-information">
    <div class="title-block w-l-column-center-compact">
      <div class="panel-title fc-d3 font-bold">
        INFORMATION
      </div>
      <div class="tip fc-d7 font-18">
        /行/内/资/讯/
      </div>
    </div>
    <div class="news-container w-l-rw-center-compact">
      <news-item></news-item>
      <news-item></news-item>
      <news-item></news-item>
    </div>
  </wit-block-content-container>
</template>

<script>
  import WitBlockContentContainer
    from "@/components/common/WitBaseUI/WitBlockContentContainer/WitBlockContentContainer";
  import NewsItem from "@/views/home/comps/HomeInformation/NewsItem";
  export default {
    name: "HomeInformation",
    components:{
      WitBlockContentContainer,
      NewsItem
    }
  }
</script>

<style scoped>
  .home-information{
    position: relative;
    margin: 50px 0 0;
    width: 100%;
    /*background-color: rgb(244, 244, 244);*/
    padding-bottom: 100px;
  }
  .home-information .title-block{
    width: 100%;
    padding: 100px 0 100px;
  }
  .home-information .title-block .panel-title{
    letter-spacing: 10px;
  }
  .home-information .title-block .tip{
    letter-spacing: 3px;
  }
</style>
