<template>
  <div class="home-carousel">
    <el-carousel :interval="10000" height="100vh">
      <el-carousel-item
        v-for="(item, index) in carouselData"
        :key="index"
        class="item">
        <div class="mask" :style="{'background-color': 'rgb(0,0,0,0.3)'}"></div>
        <el-image style="width: 100%; height: 100%"
                  :src="item.imgUrl"
                  alt=""
                  fit="cover"></el-image>
        <transition name="title"
                    appear
                    leave-active-class="animate__animated animate__bounceOutRight">
          <div class="content w-l-column-rs-compact">
            <div class="page-title">
              {{ item.title }}
            </div>
            <div class="page-sub-title">
              {{ item.subTitle }}
            </div>
          </div>
        </transition>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import {getCarouselData} from "@/network/home";

export default {
  name: "HomeCarousel",
  components: {

  },
  data () {
    return {
      carouselData:[]
    }
  },
  created() {
    getCarouselData().then(response => {
      if(response){
        this.carouselData = response
      }
    })
  },
  methods: {

  }
}
</script>

<style scoped>
.home-carousel {
  position: relative;
  height: 100vh;
}
.home-carousel .item .content {
  position: absolute;
  top: 0;
  left: 50%;
  max-width: var(--content-wrapper-width);
  width: 100%;
  height: 100%;
  margin-left: -600px;
  z-index: 2;
}
.home-carousel .item .content .page-title {
  margin: 10px 0;
}
.home-carousel .item .content .page-sub-title {
  margin: 10px 0;
}
.home-carousel .mask{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
</style>
