<template>
  <div class="partner-item w-l-row-center-compact">
    <a :href="link" target="_blank">
      <el-image style="width: 181px" :src="imgSrc" fit="cover"></el-image>
    </a>
  </div>
</template>

<script>
  export default {
    name: "PartnerItem",
    props:{
      link:{
        type: String,
        default: "javascript:void(0)"
      },
      imgSrc:{
        type: String,
        require: true
      },
      index: Number
    }
  }
</script>

<style scoped>
  .partner-item{
    width: 400px;
    height: 250px;
  }
</style>
